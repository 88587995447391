import { useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

import Rondolog from "../Rondolog";

const getAceiteInfo = (payload, setIdFailed) => {
  try {
    const data = axios
      .post(process.env.REACT_APP_DASHBOARD_API + "/aceiteVerifyId", payload)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        setIdFailed(true);
        console.log(err);
      });

    return data;
  } catch (err) {
    setIdFailed(true);
    console.log(err);
  }
  return null;
};

const confirmationButton = (payload) => {
  try {
    const data = axios
      .post(
        process.env.REACT_APP_DASHBOARD_API + "/aceiteConfirmStarter",
        payload
      )
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });

    return data;
  } catch (err) {
    console.log(err);
  }
  return null;
};

const Entrypoint = () => {
  const theme = useTheme();
  const screens = process.env.REACT_APP_SCREENS.split(" ");

  const [searchParams, setSearchParams] = useSearchParams();
  const [textInfo, setTextInfo] = useState({ status: "novo" });
  const [aceiteInfo, setAceiteInfo] = useState({ aceite: null });
  const [idFailed, setIdFailed] = useState(false);
  const id = searchParams.get("id");
  console.log(`${id}`);

  const [ipInfo, setIpInfo] = useState(null);
  console.log("EITA");
  useEffect(() => {
    fetch("https://api.ipbase.com/v1/json")
      .then((response) => response.json())
      .then((data) => {
        const toIp = {
          status: "ok",
          ip: data.ip,
          message: "",
          country: data.country_name,
          regionName: data.region_name,
          city: data.city,
          lat: data.latitude,
          lon: data.longitude,
          as: "",
          asname: "",
          query: "",
        };
        setIpInfo(toIp);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    getAceiteInfo(
      {
        id: id,
      },
      setIdFailed
    )
      .then((value) => {
        value.closingDate = value.closingDate?.slice(0, 10);
        setTextInfo(value);
      })
      .catch(() => {
        setTextInfo({ status: "inconsistente" });
      });
  }, []);

  console.log(textInfo);
  console.log(idFailed);
  console.log(ipInfo);

  return (
    <>
      {screens.includes("aceiteRondolog") && (
        <Rondolog
          theme={theme}
          textInfo={textInfo}
          idFailed={idFailed}
          aceiteInfo={aceiteInfo}
          ipInfo={ipInfo}
          id={id}
          setAceiteInfo={setAceiteInfo}
          confirmationButton={confirmationButton}
        />
      )}
    </>
  );
};

export default Entrypoint;
