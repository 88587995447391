import Header from "../../global/layoutComponents/Header";
import { Box, useTheme, Typography, Fab } from "@mui/material";
import { prettyNumber } from "../../global/utilFunctions/mathUtils";
import CircularProgress from "@mui/material/CircularProgress";

import { useState, useEffect } from "react";
import { ConfirmDialog } from "../../global/inputComponents/ConfirmDialog";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

const ConfirmationFab = (props) => {
  const theme = useTheme();
  const { disabled, icon, confirmMsg, confirmFun } = props;
  const [confirmOpen, setConfirmOpen] = useState(false);

  return (
    <Box
      justifyContent="center"
      display="flex"
      sx={{ m: 1, position: "relative" }}
    >
      <Fab
        color="neutral.light"
        aria-label="add"
        disabled={disabled}
        onClick={() => setConfirmOpen(true)}
      >
        {icon}
      </Fab>
      <ConfirmDialog
        title=""
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={confirmFun}
      >
        <Typography
          variant="h2"
          fontWeight="bold"
          sx={{
            color: theme.palette.neutral.light,
            marginBottom: "10px",
          }}
        >
          Confirmação
        </Typography>

        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{ color: theme.palette.neutral.light }}
        >
          {confirmMsg}
        </Typography>
      </ConfirmDialog>
    </Box>
  );
};

const Rondolog = (props) => {
  const theme = useTheme();

  const {
    textInfo,
    idFailed,
    aceiteInfo,
    ipInfo,
    id,
    setAceiteInfo,
    confirmationButton,
  } = props;

  if (textInfo.status == "valido" && aceiteInfo.aceite == null) {
    var title = `Termo`;

    var firstText = `Eu, ${textInfo.agregadoRazaoSocial}, ${
      textInfo.cpf_cnpj
    }, declaro ter prestado
    serviços à Rondolog Transportes Ltds. ("Rondolog"), na data 
    ${textInfo.closingDate} a meu favor o valor de 
    ${prettyNumber(textInfo.valorFace, "cents")}.`;

    var secondText = `Através deste termo, solicito, à Rondolog, o adiantamento do
    meu pagamento, concedendo por liberalidade o desconto de
    ${prettyNumber(textInfo.desagio, "cents")}, ou seja, com o
    valor total devido de 
    ${prettyNumber(textInfo.valorCompra, "cents")}, a ser pago na
    data de hoje, ou no próximo dia útil, para a conta de titularidade de  ${
      textInfo.titularidadeNome
    }, ${textInfo.titularidadeCnpjCpf}, no 
    banco ${textInfo.numeroBanco}, agência ${textInfo.agenciaBancaria}-${
      textInfo.digitoAgencia
    } e conta ${textInfo.contaBancaria}-${textInfo.digitoConta}.`;
  }
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="center" alignItems="center">
        <Header title="RondoLog" subtitle="" />
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh"
      >
        {textInfo.status == "novo" && <CircularProgress color="inherit" />}

        {(idFailed || textInfo.status == "inconsistente") && (
          <Box
            backgroundColor={theme.palette.primary.light}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ boxShadow: 0.3, minWidth: 300, minHeight: 100 }}
          >
            <Box width="100%" m="30px" display="flex">
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    sx={{ color: theme.palette.neutral.light }}
                  >
                    Link inconsistente
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {(aceiteInfo.aceite == "negado" || textInfo.status == "expirado") && (
          <Box
            backgroundColor={theme.palette.primary.light}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ boxShadow: 0.3, minWidth: 300, minHeight: 100 }}
          >
            <Box width="100%" m="30px" display="flex">
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography
                    align="center"
                    variant="h3"
                    fontWeight="bold"
                    sx={{ color: theme.palette.neutral.light }}
                  >
                    Link expirado
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {aceiteInfo.aceite == "processando" && (
          <Box
            backgroundColor={theme.palette.primary.light}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ boxShadow: 0.3, minWidth: 300, minHeight: 100 }}
          >
            <Box width="100%" m="30px" display="flex">
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography
                    align="center"
                    variant="h3"
                    fontWeight="bold"
                    sx={{ color: theme.palette.neutral.light }}
                  >
                    Seu pedido será processado
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {textInfo.status == "valido" && aceiteInfo.aceite == null && (
          <Box
            backgroundColor={theme.palette.primary.light}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ boxShadow: 0.3, minWidth: 300, minHeight: 100 }}
          >
            <Box width="100%" m="30px">
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    align="center"
                    sx={{
                      color: theme.palette.neutral.light,
                      marginBottom: "10px",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: theme.palette.neutral.light,
                      marginBottom: "3px",
                    }}
                  >
                    {firstText}
                  </Typography>

                  <Typography
                    variant="h4"
                    sx={{ color: theme.palette.neutral.light }}
                  >
                    {secondText}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      {textInfo.status == "valido" && aceiteInfo.aceite == null && (
        <ConfirmationFab
          disabled={false}
          icon={<ThumbUpIcon />}
          confirmMsg={`Confirma a solicitação de antecipação de ${prettyNumber(
            textInfo.valorFace,
            "cents"
          )} com desagio de ${prettyNumber(
            textInfo.desagio,
            "cents"
          )} resultando em ${prettyNumber(textInfo.valorCompra, "cents")}`}
          confirmFun={() => {
            confirmationButton({
              id: id,
              ipInfo: ipInfo,
              text: `${title} ${firstText} ${secondText}`,
            }).then((v) => setAceiteInfo(v));
          }}
        />
      )}
    </Box>
  );
};

export default Rondolog;
